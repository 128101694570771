// allCards.map(card => card && card.type === "character" && card.name && card.name.toLowerCase().split(" ").map((word) => word[0].toUpperCase() + word.slice(1)).join(" ").replaceAll(".", "").replaceAll("'", "")).filter((name,index,array) => array.indexOf(name) === index).filter(Boolean)

export const lorcanaNames: string[] = [
  "Ariel",
  "Cinderella",
  "Goofy",
  "Hades",
  "Heihei",
  "Lefou",
  "Lilo",
  "Maximus",
  "Mickey Mouse",
  "Minnie Mouse",
  "Moana",
  "Mr Smee",
  "Prince Phillip",
  "Pumbaa",
  "Rapunzel",
  "Sebastian",
  "Simba",
  "Stitch",
  "Timon",
  "Anna",
  "Archimedes",
  "Dr Facilier",
  "Elsa",
  "Flotsam",
  "Jafar",
  "Jetsam",
  "Magic Broom",
  "Maleficent",
  "Marshmallow",
  "Olaf",
  "Pascal",
  "Rafiki",
  "Sven",
  "The Queen",
  "The Wardrobe",
  "Tinker Bell",
  "Ursula",
  "Yzma",
  "Zeus",
  "Aladdin",
  "Beast",
  "Cheshire Cat",
  "Cruella De Vil",
  "Duke Of Weselton",
  "Flynn Rider",
  "Genie",
  "Scar",
  "Hans",
  "Horace",
  "Iago",
  "Jasper",
  "John Silver",
  "Jumba Jookiba",
  "Kuzco",
  "Lady Tremaine",
  "Mad Hatter",
  "Megara",
  "Mother Gothel",
  "Peter Pan",
  "Tamatoa",
  "Abu",
  "Captain",
  "Captain Hook",
  "Donald Duck",
  "Gaston",
  "Maui",
  "Mulan",
  "Pongo",
  "Sergeant Tibbs",
  "Te Ka",
  "Tigger",
  "Aurora",
  "Belle",
  "Chief Tui",
  "Flounder",
  "Gramma Tala",
  "Jasmine",
  "Maurice",
  "Merlin",
  "Mufasa",
  "Philoctetes",
  "Robin Hood",
  "Triton",
  "Cerberus",
  "Gantu",
  "Goons",
  "Hercules",
  "Kristoff",
  "Kronk",
  "Prince Eric",
  "Starkey",
  "Bashful",
  "Christopher Robin",
  "Cobra Bubbles",
  "Doc",
  "Dopey",
  "Eudora",
  "Grand Duke",
  "Grumpy",
  "Happy",
  "King Louie",
  "Nana",
  "Piglet",
  "Sleepy",
  "Sneezy",
  "Snow White",
  "Arthur",
  "Blue Fairy",
  "Chip The Teacup",
  "Fairy Godmother",
  "Jiminy Cricket",
  "Madam Mim",
  "Peter Pans Shadow",
  "Pinocchio",
  "Winnie The Pooh",
  "Bucky",
  "Daisy Duck",
  "Enchantress",
  "Little John",
  "Lucifer",
  "Pain",
  "Panic",
  "Pete",
  "Prince John",
  "Queen Of Hearts",
  "Ratigan",
  "Ray",
  "Tiana",
  "Virana",
  "Baloo",
  "Boun",
  "Card Soldiers",
  "Felicia",
  "Fidget",
  "Honest John",
  "Lumiere",
  "Namaari",
  "Raya",
  "Shere Khan",
  "Tuk Tuk",
  "Alice",
  "Basil",
  "Caterpillar",
  "Cogsworth",
  "Duke Weaselton",
  "Grand Pabbie",
  "Hiram Flaversham",
  "James",
  "Judy Hopps",
  "Mrs Judson",
  "Nick Wilde",
  "Noi",
  "Owl",
  "Prince Charming",
  "Rabbit",
  "Sisu",
  "The Nokk",
  "Benja",
  "Chief Bogo",
  "Eli La Bouff",
  "Lawrence",
  "Li Shang",
  "Pacha",
  "Prince Naveen",
  "The Huntsman",
  "The Prince",
  "Bernard",
  "Dalmatian Puppy",
  "Joshua Sweet",
  "Kida",
  "Miss Bianca",
  "Nani",
  "Orville",
  "Patch",
  "Perdita",
  "Pluto",
  "Rolly",
  "Wendy Darling",
  "Diablo",
  "Hydros",
  "Lena Sabrewing",
  "Magica De Spell",
  "Mama Odie",
  "Pua",
  "The Firebird",
  "Cubby",
  "Cursed Merfolk",
  "Don Karnage",
  "Helga Sinclair",
  "Lyle Tiberius Rourke",
  "Milo Thatch",
  "Morph",
  "Shenzi",
  "Sir Hiss",
  "Skippy",
  "Wildcat",
  "Zazu",
  "Della Duck",
  "Hydra",
  "Jim Hawkins",
  "Kakamora",
  "Madame Medusa",
  "Nutsy",
  "Scroop",
  "Slightly",
  "Trigger",
  "Webby Vanderquack",
  "Audrey Ramirez",
  "Dewey",
  "Flintheart Glomgold",
  "Gyro Gearloose",
  "Huey",
  "Louie",
  "Lady Marian",
  "Rufus",
  "Scrooge Mcduck",
  "Eeyore",
  "Lythos",
  "Nala",
  "Sheriff Of Nottingham",
  "Thaddeus E Klang",
  "Chernabogs Followers",
  "Chernabog",
  "Lucky",
  "Mr Snoops",
  "Magic Carpet",
  "Stratos",
  "Treasure Guardian",
  "Friar Tuck",
  "Kit Cloudkicker",
  "Billy Bones",
  "Captain Amelia",
  "Gustav The Giant",
  "Pyros",
  "Razoul",
  "Agustin Madrigal",
  "Alma Madrigal",
  "Antonio Madrigal",
  "Arges",
  "Bruno Madrigal",
  "Camilo Madrigal",
  "Chi-fu",
  "Chien-po",
  "Cri-kee",
  "Dang Hu",
  "Dolores Madrigal",
  "Fa Li",
  "Fa Zhou",
  "Félix Madrigal",
  "Flotsam & Jetsam",
  "Golden Harp",
  "Gunther",
  "Gus",
  "Hera",
  "Iduna",
  "Isabela Madrigal",
  "Jaq",
  "Julieta Madrigal",
  "Khan",
  "Ling",
  "Luisa Madrigal",
  "Magical Maid",
  "Max",
  "Mirabel Madrigal",
  "Mrs Potts",
  "Nessus",
  "Pegasus",
  "Pepa Madrigal",
  "Pico",
  "Rajah",
  "Scuttle",
  "The Fates",
  "The Muses",
  "Tick-tock",
  "Tong",
  "Tor",
  "Transformed Chef",
  "Yao",
  "Yen Sid",
  "Koda",
  "Rutt",
  "Kenai",
  "Vanellope Von Schweetz",
  "Tuke",
  "Fix‐it Felix, Jr",
  "Gazelle",
  "Alan-a-dale",
  "Wreck-it Ralph",
  "Maid Marian",
  "Bruni",
  "Earth Giant",
  "Gale",
  "White Rabbit",
  "Monstro",
  "King Of Hearts",
  "Ulf",
  "Ed",
  "Flora",
  "Merryweather",
  "Fauna",
  "Banzai",
  "Clarabelle",
  "Taffyta Muttonfudge",
  "Wrech-it Ralph",
  "Turbo",
  "Rancis Fluggerbutter",
  "Snowanna Rainbeau",
  "Denahi",
  "King Candy",
  "Chaca",
  "Chicha",
  "Ludwig Von Drake",
  "Tanana",
  "Tipo",
  "Rudy",
  "Royal Guard",
  "Sir Ector",
];

// allCards.map(card => card && card.type === "character" && card.title && card.title.toLowerCase().split(" ").map((word) => word[0].toUpperCase() + word.slice(1)).join(" ").replaceAll(".", "").replaceAll("'", "")).filter((name,index,array) => array.indexOf(name) === index).filter(Boolean)
export const lorcanaTitles: string[] = [
  "On Human Legs",
  "Spectacular Singer",
  "Gentle And Kind",
  "Musketeer",
  "King Of Olympus",
  "Lord Of The Underworld",
  "Boat Snack",
  "Bumbler",
  "Making A Wish",
  "Palace Horse",
  "Relentless Pursuer",
  "True Friend",
  "Beloved Princess",
  "Of Motunui",
  "Loyal First Mate",
  "Dragonslayer",
  "Friendly Warthog",
  "Gifted With Healing",
  "Court Composer",
  "Protective Cub",
  "Carefree Surfer",
  "New Dog",
  "Rock Star",
  "Grub Rustler",
  "Heir To Arendelle",
  "Highly Educated Owl",
  "Agent Provocateur",
  "Charlatan",
  "Remarkable Gentleman",
  "Queen Regent",
  "Snow Queen",
  "Spirit Of Winter",
  "Ursulas Spy",
  "Keeper Of Secrets",
  "Wicked Sorcerer",
  "Bucket Brigade",
  "Biding Her Time",
  "Sorceress",
  "Persistent Guardian",
  "Wayward Sorcerer",
  "Friendly Snowman",
  "Rapunzels Companion",
  "Mysterious Sage",
  "Official Ice Deliverer",
  "Wicked And Vain",
  "Belles Confidant",
  "Peter Pans Ally",
  "Power Hungry",
  "Alchemist",
  "God Of Lightning",
  "Prince Ali",
  "Wolfsbane",
  "Not All There",
  "Miserable As Usual",
  "Opportunistic Official",
  "Charming Rogue",
  "On The Job",
  "Powers Unleashed",
  "The Ever Impressive",
  "Shameless Firebrand",
  "Scheming Prince",
  "No-good Scoundrel",
  "Loud-mouthed Parrot",
  "Common Crook",
  "Alien Pirate",
  "Renegade Scientist",
  "Temperamental Emperor",
  "Wicked Stepmother",
  "Gracious Host",
  "Pulling The Strings",
  "Artful Rogue",
  "Steamboat Pilot",
  "Selfish Manipulator",
  "Never Landing",
  "Drab Little Crab",
  "Most Helpful",
  "Mischievous Monkey",
  "Heroic Outlaw",
  "Street Rat",
  "Colonels Lieutenant",
  "Ruthless Pirate",
  "Boisterous Fowl",
  "Ice Surfer",
  "Arrogant Hunter",
  "Daredevil",
  "Instigator",
  "Monstrous Dragon",
  "Hero To All",
  "Brave Little Tailor",
  "Always Classy",
  "Chosen By The Ocean",
  "Imperial Soldier",
  "Fearless Fighter",
  "Ol Rascal",
  "Letting Down Her Hair",
  "Fiery Usurper",
  "Courageous Cat",
  "Abomination",
  "The Burning One",
  "Wonderful Thing",
  "Whoseit Collector",
  "Briar Rose",
  "Dreaming Guardian",
  "Regal Princess",
  "Inventive Engineer",
  "Strange But Special",
  "Respected Leader",
  "Strutting His Stuff",
  "Voice Of Reason",
  "Storyteller",
  "Infernal Schemer",
  "Disguised",
  "Queen Of Agrabah",
  "Sinister Visitor",
  "Uninvited",
  "World-famous Inventor",
  "Self-appointed Mentor",
  "Detective",
  "King Of The Pride Lands",
  "Trainer Of Heroes",
  "Unrivaled Archer",
  "Mastermind",
  "So Shiny!",
  "The Sea King",
  "Cornered Swordsman",
  "Hardheaded",
  "Captain Of The Jolly Roger",
  "Forceful Duelist",
  "Thinking A Happy Thought",
  "Three-headed Dog",
  "Galactic Federation Captain",
  "Maleficents Underlings",
  "Thirteenth In Line",
  "True Hero",
  "Official Ice Master",
  "Right-hand Man",
  "Galactic Hero",
  "Demigod",
  "Dashing And Brave",
  "Future King",
  "Returned King",
  "Rightful Heir",
  "Hooks Henchman",
  "Heartless",
  "Giant Fairy",
  "Tiny Tactician",
  "Hopeless Romantic",
  "Adventurer",
  "Ballroom Sensation",
  "Simple Educator",
  "Leader Of The Seven Dwarfs",
  "Always Playful",
  "Accomplished Seamstress",
  "Baritone Bully",
  "Advisor To The King",
  "Bad-tempered",
  "Good-natured",
  "Jungle Vip",
  "Friendly Face",
  "Betrayed Leader",
  "Free Spirit",
  "Reflecting",
  "Darling Family Pet",
  "Very Small Animal",
  "Gifted Artist",
  "Sunshine",
  "Nodding Off",
  "Very Allergic",
  "Lost In The Forest",
  "Unexpected Houseguest",
  "Well Wisher",
  "Commanding Presence",
  "Regal Monarch",
  "Wizards Apprentice",
  "Rewarding Good Deeds",
  "Gentle Soul",
  "Savvy Opportunist",
  "Gloves Off",
  "Here To Help",
  "Mystic Armorer",
  "Pure Heart",
  "Persistent Presence",
  "Pinocchios Conscience",
  "Wanted Llama",
  "Fox",
  "Purple Dragon",
  "Rival Of Merlin",
  "Snake",
  "Crab",
  "Goat",
  "Rabbit",
  "Shapeshifter",
  "Squirrel",
  "Not Sewn On",
  "Star Attraction",
  "On The Run",
  "Talkative Puppet",
  "Hunny Wizard",
  "Scary Beyond All Reason",
  "Without Beauty Sleep",
  "Trained Swordsman",
  "Relentless",
  "Bookworm",
  "Hidden Archer",
  "Squirrel Squeak Tutor",
  "Always Grinning",
  "From The Shadows",
  "Secret Agent",
  "Perfect Gentleman",
  "Sleepwalker",
  "Fortune Teller",
  "Unexpected Judge",
  "Confident Vagabond",
  "His Own Biggest Fan",
  "Scheming Suitor",
  "Loyal Friend",
  "Cunning Cat",
  "Underworld Imp",
  "Bad Guy",
  "Greediest Of All",
  "Quick-tempered",
  "Criminal Mastermind",
  "Easygoing Firefly",
  "Disguised Peddler",
  "True Princess",
  "Fang Chief",
  "Fun-loving Bear",
  "Precocious Enterpreneur",
  "Full Deck",
  "Not Again!",
  "Always Hungry",
  "Ratigan’s Henchman",
  "Not That Honest",
  "Imperious Queen",
  "Overbearing Matriarch",
  "Hotheaded Candelabra",
  "Stylish Surfer",
  "Wide-eyed Diver",
  "Zipping Around",
  "Withered And Wicked",
  "Soldier In Training",
  "Nemesis",
  "Impulsive Ruler",
  "Sensing Weakness",
  "Very Large Mouse",
  "Headstrong",
  "Leader Of Heart",
  "Warrior Of Kumandra",
  "Vicious Cheater",
  "Menacing Predator",
  "One Of A Kind",
  "Wrecking Ball",
  "Growing Girl",
  "Great Mouse Detective",
  "Of Baker Street",
  "Perceptive Investigator",
  "Calm And Collected",
  "Grandfather Clock",
  "Talking Clock",
  "Fashionable Cruiser",
  "Perfectly Wretched",
  "Small-time Crook",
  "Intellectual Powerhouse",
  "Oldest And Wisest",
  "Toymaker",
  "Role Model",
  "Heir Of Agrabah",
  "Optimistic Officer",
  "Housekeeper",
  "Wily Fox",
  "Orphaned Thief",
  "Logical Lecturer",
  "Heir To The Throne",
  "Reluctant Host",
  "Divine Water Dragon",
  "Water Spirit",
  "Having A Think",
  "Forbidding Recluse",
  "Selfless Protector",
  "Tragic Hero",
  "Guardian Of The Dragon Gem",
  "Respected Officer",
  "Knight In Training",
  "Stouthearted",
  "Deep-sea Diver",
  "Big Daddy",
  "Knight For A Day",
  "Divine Hero",
  "Hero In Training",
  "Dreadnought",
  "Royal Vizier",
  "Junior Chipmunk",
  "Jealous Manservant",
  "Archery Instructor",
  "Industrial Model",
  "Morning Mist",
  "Village Leader",
  "Penniless Royal",
  "Capricious Monarch",
  "Capable Fighter",
  "Reluctant Enforcer",
  "Never Gives Up",
  "Celebrating Princess",
  "Diligent Waitress",
  "Brand-new Agent",
  "Tail Wagger",
  "The Doctor",
  "Atlantean",
  "Protector Of Atlantis",
  "International Rescue Aid Society Agent",
  "Protective Sister",
  "Flying Ace",
  "Little Menace",
  "Devoted Mother",
  "Pooh Pirate Captain",
  "Determined Defender",
  "Friendly Pooch",
  "Determined Father",
  "Wonderland Empress",
  "Hungry Pup",
  "Generous Fairy",
  "Talented Sailor",
  "Tea Alchemist",
  "Loyal Familiar",
  "Supportive Friend",
  "Ice Titan",
  "Pretty Polly",
  "Lamp Thief",
  "Striking Illusionist",
  "Rebellious Teenager",
  "Dancing Duster",
  "Fast Cleaner",
  "Ambitious Witch",
  "The Midas Touch",
  "Thieving Sorceress",
  "Mistress Of All Evil",
  "Voice Of Wisdom",
  "Potbellied Buddy",
  "Force Of Destruction",
  "Sea Witch",
  "Mighty Lost Boy",
  "Ursulas Handiwork",
  "Pirate Prince",
  "Riffraff",
  "Femme Fatale",
  "Vengeful Partner",
  "Cunning Mercenary",
  "Clever Cartographer",
  "King Of Atlantis",
  "Space Goo",
  "Lost Boy Leader",
  "False King",
  "Daydreamer",
  "Hyena Pack Leader",
  "Aggravating Asp",
  "Energetic Rabbit",
  "Devious Pirate",
  "Deceiver",
  "Deceiver Of All",
  "Mechanic",
  "Pride Land’s Manager",
  "Adventurous Collector",
  "Master Swordsman",
  "Unstoppable Mom",
  "Accidental Explorer",
  "Deadly Serpent",
  "Space Traveler",
  "Thrill Seeker",
  "Menacing Sailor",
  "The Boss",
  "Winged Demigod",
  "Whale",
  "Spirited Scholar",
  "Born Leader",
  "Undeterred Voyager",
  "Vulture Henchman",
  "Never Land Hero",
  "Pirate’s Bane",
  "Expert Helmsman",
  "Backstabber",
  "Scrappy Cub",
  "Lost Boy",
  "Little Rocket",
  "Imprecise Shooter",
  "Enthusiastic Duck",
  "The Engineer",
  "Showy Nephew",
  "Lone Cheater",
  "Keeper Of Ancient Stories",
  "Spirit Of The Ocean",
  "Gadget Genius",
  "Savvy Nephew",
  "Bandleader",
  "Chill Nephew",
  "Adorable Dreamer",
  "Mystical Maven",
  "Orphanage Cat",
  "Richest Duck In The World",
  "Very Clever Fairy",
  "Authority On Peter Pan",
  "Proud Of Motunui",
  "Overstuffed Donkey",
  "Wrathful Ruler",
  "Right-hand Woman",
  "Royal Warrior",
  "Resourceful Outlaw",
  "Robins Companion",
  "Rock Titan",
  "Trumpeter",
  "Funky Spelunker",
  "Bumbling Mate",
  "Fierce Friend",
  "Beloved Outlaw",
  "Champion Of Sherwood",
  "Corrupt Official",
  "Fighting Prince",
  "Rightful King",
  "Metal Head",
  "Creatures Of Evil",
  "Von Bruinwald Xiii",
  "Evildoer",
  "The 15th Puppy",
  "Musical Artist",
  "Inept Businessman",
  "The Big Sweeper",
  "Flying Rug",
  "Mystical Fighter",
  "Tornado Titan",
  "Hateful Rival",
  "Protector Of The Cave",
  "Priest Of Nottingham",
  "Tough Guy",
  "Covert Agent",
  "Keeper Of The Map",
  "First In Command",
  "Cramped In The Lamp",
  "Navigator",
  "Spunky Bear Cub",
  "Mickeys Clever Friend",
  "Uncle Moneybags",
  "Mirror Seeker",
  "Terror Of The Kingdom",
  "Greedy Treasure Seeker",
  "Stalwart Explorer",
  "Champion Of The Pride Lands",
  "Lava Titan",
  "Palace Guard",
  "Clumsy Dad",
  "Brave Rescuer",
  "Resolute Swordsman",
  "Family Matriarch",
  "Braving The Storm",
  "True-hearted",
  "Animal Expert",
  "The Cyclops",
  "Determined Mermaid",
  "Singing Mermaid",
  "Sonic Warrior",
  "Treasure Collector",
  "Lore Guardian",
  "Tranquil Princess",
  "Thick-skinned",
  "Wounded",
  "Accomplished Mystic",
  "Untrained Mystic",
  "Bold Uniter",
  "Out Of The Shadows",
  "Undetected Uncle",
  "Prankster",
  "Imperial Advisor",
  "Melody Weaver",
  "Majordomo",
  "Lucky Cricket",
  "Lovely Lady",
  "Musketeer Spy",
  "Talon Chief",
  "Devoted Herald",
  "Maleficents Spy",
  "Easy Listener",
  "Buccaneer",
  "Musketeer Soldier",
  "Storm Chaser",
  "Mulans Mother",
  "Mulans Father",
  "Fun-loving Family Man",
  "Entangling Eels",
  'Ursulas "baby"',
  "Collectors Companion",
  "Frenemy",
  "Despicable Dealer",
  "Enchanter Of The Land",
  "Musketeer Swordsman",
  "Super Goof",
  "Interior Designer",
  "Champion Of Cheese",
  "Double Dealer",
  "Meticulous Plotter",
  "Noble Scoundrel",
  "Bumbling Rooster",
  "Queen Of The Gods",
  "Beloved Hero",
  "Clumsy Kid",
  "Daring Demigod",
  "Caring Mother",
  "Golden Child",
  "Connoisseur Of Climbing",
  "Desert Warrior",
  "Terror Of The Realm",
  "Excellent Cook",
  "Beloved Steed",
  "Opportunistic Flunky",
  "Generals Son",
  "Imperial Captain",
  "Valorous General",
  "Magically Strong One",
  "Rock Of The Family",
  "Fiery Friend",
  "Aerial Cleaner",
  "Brigade Commander",
  "Illuminary Keeper",
  "Lively Sweeper",
  "Feather Duster",
  "Terrifying Snowman",
  "Loyal Sheepdog",
  "Captivating Cynic",
  "Liberated One",
  "Leader Of The Band",
  "Musketeer Captain",
  "Playful Sorcerer",
  "Standard Bearer",
  "Musketeer Champion",
  "Gift Of The Family",
  "Prophecy Finder",
  "Enchanted Teapot",
  "Armored Fighter",
  "Elite Archer",
  "Enemy Of Entanglement",
  "Injured Soldier",
  "Heir Of Fang",
  "River Guardian",
  "Acrobatic Baby",
  "Carrot Enthusiast",
  "Trusting Companion",
  "Immortal Sidekick",
  "Inquisitive Pet",
  "Cloud Racer",
  "Flying Steed",
  "Gift For Hercules",
  "Weather Maker",
  "Born To Cheat",
  "Rotten Guy",
  "Shadow Finder",
  "No-nonsense Instructor",
  "Helpful Toucan",
  "Sturdy Swordsman",
  "Rescue Dog",
  "Seafaring Prince",
  "Ursulas Groom",
  "Gallant Defender",
  "Vanquisher Of Foes",
  "Warden Of The Woods",
  "Royal Protector",
  "Appreciative Artist",
  "Fierce Protector",
  "Unstoppable Force",
  "Expert On Humans",
  "Daring Visitor",
  "Emboldened Warrior",
  "Empowered Sibling",
  "Wise Friend",
  "Alien Dancer",
  "Only One Eye",
  "Proclaimers Of Heroes",
  "Diviner",
  "Ever-present Pursuer",
  "Survivor",
  "Florist",
  "Castle Stove",
  "Champion Of Atlantica",
  "Discerning King",
  "Young Prince",
  "Curious Partner",
  "Erics Bride",
  "Mad Sea Witch",
  "Sea Witch Queen",
  "Vanessa",
  "Powerful Sorcerer",
  "Lively Partner",
  "Mr Lightning Bolts",
  "Talkative Cub",
  "Mischievous Cub",
  "Ukulele Player",
  "Northern Moose",
  "Big Brother",
  "Sugar Rush Champ",
  "Junior Cake Decorator",
  "Candy Mechanic",
  "Trusty Builder",
  "Pop Star",
  "Niceland Steward",
  "Reindeer Keeper",
  "Family Gatherer",
  "Drum Major",
  "Donalds Date",
  "Delighted Sightseer",
  "Determined Explorer",
  "Sugar Rush Princess",
  "Rockin Rooster",
  "Admiral Underpants",
  "Lady Of The Lists",
  "Reindeer Steed",
  "Compassionate Friend",
  "Fearsome Queen",
  "Mythical Spirit",
  "Illuminary Watchman",
  "Turtle",
  "Exasperated Owl",
  "Fire Salamander",
  "Living Mountain",
  "Wind Spirit",
  "Royal Herald",
  "Elephant",
  "Entertaining Muscle",
  "Mystical Majesty",
  "Electrified Owl",
  "The Fifth Spirit",
  "Main Attraction",
  "Happy Passenger",
  "Vexed Partygoer",
  "Whale Of A Whale",
  "Cruel Sorceress",
  "Vengeful Sorceress",
  "Shaman Duelist",
  "Eager Acolyte",
  "Monarch Of Wonderland",
  "Family Copycat",
  "Timely Contestant",
  "Scars Accomplice",
  "Camp Cook",
  "Advisor To Mufasa",
  "Mime",
  "Laughing Hyena",
  "Good Fairy",
  "Archery Contestant",
  "Fake Flamingo",
  "Gluttonous Predator",
  "Hysterical Partygoer",
  "Odious Mutineer",
  "Swordsman Of The Realm",
  "Light On Her Hooves",
  "Diplomatic Queen",
  "Clumsy Guest",
  "Taunting Hyena",
  "Sneaky Sleuth",
  "Conceited Manipulator",
  "Contented Wallflower",
  "Head Hyena",
  "Unwavering Schemer",
  "Vengeful Lion",
  "Ruthless Rival",
  "Demolition Dude",
  "Team Champion",
  "Royal Hack",
  "Pie Slinger",
  "Chocolate Charger",
  "Betrayer",
  "Cool Competitor",
  "Spotless Food-fighter",
  "Enthusiastic Dancer",
  "Raging Rat",
  "Crowd Favorite",
  "Avenging Brother",
  "Steamboat Rival",
  "Sour Speedster",
  "Sharpshooter",
  "Pure Paragon",
  "Pastry Chomper",
  "Novice Sparrow",
  "Daisys Date",
  "Party Crasher",
  "Random Roster Racer",
  "Adventurous Successor",
  "Dazzling Dancer",
  "Sovereign Of Sugar",
  "Impressive Daughter",
  "Cruelest Of All",
  "Aficionado Of Antiquities",
  "Opportunistic Briber",
  "Back From The Bermudas",
  "Emperors Guide",
  "Fairest Of All",
  "Bushel Britches",
  "Dedicated Mother",
  "Gold Lover",
  "Crown Of The Council",
  "Selfish Emperor",
  "Ruler Of Pride Rock",
  "Self-proclaimed Genius",
  "Quick-thinking Inventor",
  "Practiced Detective",
  "Sweet Abomination",
  "Focused Flatfoot",
  "Wise Woman",
  "Growing Son",
  "Of The Ball",
  "Intellectual Visionary",
  "Team Underdog",
  "Tyrannical Hypnotist",
  "Lost Prince",
  "Groove Disrupter",
  "Bovine Protector",
  "Food Fight Defender",
  "Sluggish Knight",
  "Unlicensed Investigator",
  "Protective Rooster",
  "Noisy Knight",
  "Knight Apprentice",
  "Resolute Daughter",
  "Fair-hearted",
  "Unjustly Treated",
  "Head Of Security",
  "Skeptical Knight",
  "Wrestling Champ",
  "Castle Lord",
  "Adoring Knight",
  "Wart",
  "Lively Knight",
  "Son Of Mufasa",
  "Bold Knight",
  "King Victorious",
  "Games Referee",
];
