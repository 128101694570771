"use client";

import { init, tx, id } from "@instantdb/react";
import { env } from "~/env.mjs";
import type {
  Game,
  LogEntry,
  Match,
  MatchMove,
  NotificationType,
} from "@lorcanito/engine";
import { useCallback, useEffect, useRef, useState } from "react";
import { isUserIdle, PresenceType } from "~/client/hooks/useIsUserIdle";
import { api } from "~/trpc/react";
import { useAuth, useUser } from "@clerk/nextjs";
import { debounce } from "~/libs/debounce";
import { Presence, RoomSchema, Schema } from "~/libs/3rd-party/instant/schema";
import { Lobby } from "~/server/persistence/types";
import { Diff } from "deep-diff";
import { usePlayerUsername } from "~/client/hooks/usePlayerUsername";

export const instantClientSideDB = init<Schema, RoomSchema>({
  appId: env.NEXT_PUBLIC_INSTANT_APP_ID,
});

export function useInstantSignIn() {
  const { user } = useUser();
  const instantDBAuth = instantClientSideDB.useAuth();

  const email = user?.emailAddresses[0]?.emailAddress;
  const { data: token } = api.instant.getToken.useQuery({
    email: email,
  });

  useEffect(() => {
    if (instantDBAuth.user) {
      console.log("Already signed in to Instant", instantDBAuth.user);
      return;
    }
    if (!user || !token) {
      return;
    }

    console.log(`Signing ${email} into Instant with token: ${token}`);
    instantClientSideDB.auth.signInWithToken(token).then(console.log);
  }, [email, token, instantDBAuth.user]);

  return instantDBAuth;
}

export function useInstantAuth() {
  if (process.env.NODE_ENV === "development") {
    useInstantSignIn();
  } else {
    useSignInToInstantWithClerk();
  }
}

export function useSignInToInstantWithClerk() {
  const { getToken } = useAuth();
  const { isLoading, error, user } = instantClientSideDB.useAuth();

  async function signInToInstantWithClerkToken() {
    const idToken = await getToken();

    if (!idToken) {
      // No jwt, can't sign in to instant
      console.error("No JWT token found, can't sign in to instant");
      return;
    }

    const params = {
      clientName: "clerk",
      idToken: idToken,
      nonce: undefined,
    };
    console.log("Signing in to instant with params", params);
    await instantClientSideDB.auth.signInWithIdToken(params);
  }

  useEffect(() => {
    signInToInstantWithClerkToken();
  }, []);

  return { isLoading, error, user, signInToInstantWithClerkToken };
}

export async function updateMoveResult({
  match,
  move,
  moveLogs,
  moveNotifications,
  moveDiff,
  hashBefore,
  hashAfter,
}: {
  match: Match;
  move: MatchMove;
  moveLogs: LogEntry[];
  moveNotifications: NotificationType[];
  moveDiff: Diff<Match, Match>[] | undefined;
  hashBefore: string;
  hashAfter: string;
}) {
  const moveId = id();
  move.id = moveId;

  await instantClientSideDB.transact([
    //@ts-expect-error
    tx.matches?.[match.matchId]
      .update({
        match,
        hashBefore,
        hashAfter,
        updatedAt: Date.now(),
      })
      .link({ games: match.gameId }),
    // tx.moves[moveId]
    //   .update({ ...move, diff: [], createdAt: Date.now() })
    //   .link({ matches: match.matchId }),
  ]);

  // const batch = [];
  //
  // moveLogs.forEach((log) => {
  //   //@ts-expect-error
  //   log.id = id();
  //   batch.push(
  //     //@ts-expect-error
  //     tx.logs[log.id]
  //       .update({ ...log, createdAt: Date.now() })
  //       .link({ moves: moveId }),
  //   );
  // });
  //
  // moveNotifications.forEach((notification) => {
  //   //@ts-expect-error
  //   notification.id = id();
  //   batch.push(
  //     //@ts-expect-error
  //     tx.notifications[notification.id]
  //       .update({ ...notification, createdAt: Date.now() })
  //       .link({ moves: moveId }),
  //   );
  // });
  //
  // await instantClientSideDB.transact(batch);
}

// THere's a serverside equivalent of this function
export function updateInstantMatchState({
  match,
  hashBefore,
  hashAfter,
}: {
  match: Match;
  hashBefore: string;
  hashAfter: string;
}) {
  return instantClientSideDB.transact(
    //@ts-expect-error
    tx.matches?.[match.matchId].update({
      match,
      hashBefore,
      hashAfter,
      lastUpdated: Date.now(),
    }),
  );
}

export async function updateInstantMatchUndoState({
  undoState,
}: {
  undoState: Match;
}) {
  return instantClientSideDB.transact(
    //@ts-expect-error
    tx.matches?.[undoState.matchId].update({
      undoState,
      lastUpdated: Date.now(),
    }),
  );
}

export function useWriteOnlyInstantServerPresence() {
  const username = usePlayerUsername();

  const serverRoom = instantClientSideDB.room("server", "main");

  const { publishPresence } = serverRoom.usePresence({
    // Will not trigger re-renders on presence changes
    peers: [],
    user: false,
  });

  useEffect(() => {
    const presence: Presence = {
      name: username,
      status: "online",
    };
    publishPresence(presence);
  }, [username]);
}

export function useInstantServerPresence() {
  const username = usePlayerUsername();

  const serverRoom = instantClientSideDB.room("server", "main");

  const { publishPresence, user, peers, error, isLoading } =
    serverRoom.usePresence();

  useEffect(() => {
    const presence: Presence = {
      name: username,
      status: "online",
    };

    publishPresence(presence);
  }, [username]);

  if (isLoading || error) {
    return { user: {}, peers: {} };
  }

  return { user, peers };
}

export function useInstantMatchPresence(gameId: string, matchId: string) {
  const username = usePlayerUsername();
  const serverRoom = instantClientSideDB.room("match", `${gameId}/${matchId}`);

  const [presence, setPresence] = useState<Presence>({
    name: username,
    status: "online",
  });

  const onPresenceChange = useCallback(
    (presence: PresenceType) => {
      const isIdle = presence.type === "idle";
      setPresence((presence) => ({
        ...presence,
        status: isIdle ? "idle" : "online",
      }));
    },
    [setPresence],
  );

  isUserIdle({ onPresenceChange });

  const { publishPresence, user, peers, error, isLoading } =
    serverRoom.usePresence();

  useEffect(() => {
    publishPresence(presence);
  }, []);

  if (isLoading || error) {
    return { user: {}, peers: {} };
  }

  return { user, peers };
}

export function useInstantGame(gameId: string, ssrGame: Game) {
  const query = {
    games: {
      $: {
        where: {
          id: gameId,
        },
      },
    },
  } as const;

  const { isLoading, error, data } = instantClientSideDB.useQuery(query);

  return { isLoading, error, game: data?.games[0] || ssrGame } as const;
}

export function useInstantMatch(
  matchId: string,
  callback: (match: Match) => void,
) {
  const query = {
    matches: {
      $: {
        where: {
          matchId: matchId,
        },
      },
    },
  } as const;

  const { isLoading, error, data } = instantClientSideDB.useQuery(query);
  const debouncedCallback = useCallback(debounce(callback, 333), [callback]);

  useEffect(() => {
    if (error) {
      console.log(error);
      return;
    }

    if (isLoading) {
      console.log("Loading...", query);
      return;
    }

    if (data) {
      const result: Match | undefined = data.matches[0]?.match;

      if (!result) {
        console.error("Match Not Found: ", data);
      } else {
        debouncedCallback(result);
      }
    }
  }, [debouncedCallback, isLoading, error, data]);
}

export function useInstantLobby(lobbyId: string) {
  const query = {
    lobbies: {
      $: {
        where: {
          id: lobbyId,
        },
      },
    },
  } as const;

  const { isLoading, error, data } = instantClientSideDB.useQuery(query);

  const lobby = data?.lobbies[0];
  return { isLoading, error, lobby } as const;
}
