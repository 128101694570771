import { useUser } from "@clerk/nextjs";
import { generateUserName } from "~/libs/name-generator/generator";

const randomName = generateUserName();

export function usePlayerUsername() {
  const { user, isLoaded } = useUser();
  if (!isLoaded || !user) {
    return "Anonymous";
  }

  return user.publicMetadata.userName || "Not Found";
}
