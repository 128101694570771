import { useCallback, useState } from "react";
import { useIdleTimer } from "react-idle-timer";

const timeout = 300_000;
const promptBeforeIdle = 30_000;
export type PresenceType =
  | { type: "idle" }
  | { type: "active"; prompted: boolean };

export function isUserIdle({
  onIdle,
  onActive,
  onPrompt,
  onPresenceChange,
}: {
  onIdle?: () => void;
  onActive?: () => void;
  onPrompt?: () => void;
  onPresenceChange?: (presence: PresenceType) => void;
} = {}) {
  const [isIdle, setIsIdle] = useState<boolean>(false);
  const [remaining, setRemaining] = useState<number>(timeout);

  const onIdleCallback = useCallback(() => {
    setIsIdle(true);
    if (onIdle) {
      onIdle();
    }
  }, [setIsIdle]);

  const onActiveCallback = useCallback(() => {
    setIsIdle(false);
    if (onActive) {
      onActive();
    }
  }, [setIsIdle]);

  const onPromptCallback = useCallback(() => {
    setIsIdle(true);
    if (onPrompt) {
      onPrompt();
    }
  }, [setIsIdle]);

  const { getRemainingTime, activate, isPrompted } = useIdleTimer({
    onIdle: onIdleCallback,
    onActive: onActiveCallback,
    onPrompt: onPromptCallback,
    onPresenceChange,
    timeout,
    promptBeforeIdle,
    throttle: 1000,
  });

  return {
    isIdle,
    isPrompted,
    remaining,
    setRemaining,
    getRemainingTime,
    activate,
  };
}
